import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  DialogContentText,
  TablePagination,
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentGalleryItem, setCurrentGalleryItem] = useState(null);
  const [form, setForm] = useState({
    description: '',
    file: null,
  });
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currentGalleryItemToDelete, setCurrentGalleryItemToDelete] = useState(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const userRole = useSelector(state => state.user.userRole);
  const username = useSelector(state => state.user.username);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGalleryItems();
  }, []);

  const fetchGalleryItems = async () => {
    try {
      const response = await axiosInstance.get('/gallery/getImages');
      setGalleryData(response.data);
    } catch (error) {
      if (error.response?.status === 403) {
        localStorage.removeItem('token');
        navigate('/');
      }
      handleSnackbarError(error);
    }
  };

  const handleDelete = async id => {
    try {
      setCurrentGalleryItemToDelete(id);
      setOpenDeleteConfirmation(true);
    } catch (error) {
      handleSnackbarError(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/gallery/deleteImage/${currentGalleryItemToDelete}`);
      fetchGalleryItems();
      setOpenDeleteConfirmation(false);
    } catch (error) {
      handleSnackbarError(error);
    }
  };

  const handleEdit = item => {
    setCurrentGalleryItem(item);
    setForm({
      description: item.description,
      file: null,
    });
    setOpenEdit(true);
  };

  const handleSave = async () => {
    try {
      const data = {
        ...form,
        createdBy: username,
      };
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      await axiosInstance.put(`/gallery/editImage/${currentGalleryItem._id}`, formData);
      setOpenEdit(false);
      setForm({
        description: '',
        file: null,
      });
      fetchGalleryItems();
    } catch (error) {
      handleSnackbarError(error);
    }
  };

  const handleChange = e => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setForm(prevForm => ({ ...prevForm, [name]: files[0] }));
    } else {
      setForm(prevForm => ({ ...prevForm, [name]: value }));
    }
  };

  const handleAdd = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      try {
        const data = {
          ...form,
          createdBy: username,
        };
        const formData = new FormData();
        Object.keys(data).forEach(key => {
          formData.append(key, data[key]);
        });

        await axiosInstance.post('/gallery/addImage', formData);
        setOpenAdd(false);
        fetchGalleryItems();
        setForm({
          description: '',
          file: null,
        });
      } catch (error) {
        handleSnackbarError(error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.description) newErrors.description = 'Description is required';
    if (!form.file) newErrors.file = 'File is required';
    return newErrors;
  };

  const handleSnackbarError = error => {
    if (error.response?.status === 403) {
      localStorage.removeItem('token');
      navigate('/');
    }
    setApiError(`Error: ${error.message}`);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setApiError('');
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <h1>Gallery</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenAdd(true)}
      >
        Add Image
      </Button>

      <TableContainer component={Paper}>
        <Table
          style={{ tableLayout: 'fixed', marginTop: '10px' }}
          className="small-font"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '30%', border: '1px solid #ddd' }}>
                Image
              </TableCell>
              <TableCell style={{ width: '35%', border: '1px solid #ddd' }}>
                Content
              </TableCell>
              <TableCell style={{ width: '25%', border: '1px solid #ddd' }}>
                Created By
              </TableCell>
              <TableCell style={{ width: '20%', border: '1px solid #ddd' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {galleryData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(item => (
                <TableRow key={item._id}>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <img
                      src={item.imageUrl}
                      alt="gallery"
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    {item.description}
                  </TableCell>
                  <TableCell style={{ width: '24%', border: '1px solid #ddd' }}>
                    {item.createdBy}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <Button onClick={() => handleEdit(item)}>Edit</Button>
                    {userRole === 'Admin' &&
                      <Button onClick={() => handleDelete(item._id)}>
                        Delete
                      </Button>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={galleryData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for editing a gallery item */}
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit Gallery Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={form.description || ''}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
          />
          <input type="file" name="file" onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding a new gallery item */}
      <Dialog open={openAdd} onClose={() => setOpenAdd(false)}>
        <DialogTitle>Add Gallery Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={form.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
          />
          <input type="file" name="file" onChange={handleChange} />
          {errors.file && <p style={{ color: 'red' }}>{errors.file}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd(false)}>Cancel</Button>
          <Button onClick={handleAdd}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for displaying API errors */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={apiError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
};

export default Gallery;
