import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  FormControl,
  Snackbar,
  SnackbarContent,
  DialogContentText,
  Pagination,
  Box
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axiosInstance from '../axiosInstance';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Careers = () => {
  const [careers, setCareers] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentCareer, setCurrentCareer] = useState(null);
  const [expandedCareer, setExpandedCareer] = useState(null); // State to track expanded descriptions
  const userRole = useSelector((state) => state.user.userRole); 
  const username = useSelector((state) => state.user.username);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    jobPosition: '',
    jobDescription: '',
    location: '',
    jobType: '',
    experienceFrom: '',
    experienceTo: '',
    status: '',
    createdBy: ''
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Define the number of items per page

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    fetchCareers();
  }, []);

  const fetchCareers = async () => {
    try {
      const response = await axiosInstance.get('/jobOpenings/getOpenings');
      setCareers(response.data);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/jobOpenings/deleteOpening/${currentCareer._id}`);
      setOpenDelete(false);
      fetchCareers();
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleEdit = (career) => {
    setCurrentCareer(career);
    setForm({
      jobPosition: career.jobPosition,
      jobDescription: career.jobDescription,
      location: career.location,
      jobType: career.jobType,
      experienceFrom: career.experienceFrom,
      experienceTo: career.experienceTo,
      status: career.status,
      createdBy: career.createdBy
    });

    setOpenEdit(true);
  };

  const handleSave = async () => {
    try {
      const data = {
        ...form,
        createdBy: username
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      await axiosInstance.put(`/jobOpenings/editOpening/${currentCareer._id}`, formData);
      setOpenEdit(false);
      setForm({
        jobPosition: '',
        jobDescription: '',
        location: '',
        jobType: '',
        experienceFrom: '',
        experienceTo: '',
        status: '',
        createdBy: ''
      });
      fetchCareers();
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleAdd = async () => {
    try {
      const data = {
        ...form,
        createdBy: username
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      await axiosInstance.post('/jobOpenings/addOpenings', formData);
      setOpenAdd(false);
      fetchCareers();
      setForm({
        jobPosition: '',
        jobDescription: '',
        location: '',
        jobType: '',
        experienceFrom: '',
        experienceTo: '',
        status: '',
        createdBy: ''
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("token");
      navigate("/");
    }
    console.error('API error:', error);
    setApiError('Failed to perform operation. Please try again.');
    setSnackbarOpen(true);
  };

  const handleDescriptionChange = (content) => {
    setForm((prevForm) => ({ ...prevForm, jobDescription: content }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setApiError('');
  };

  const openDeleteConfirmation = (career) => {
    setCurrentCareer(career);
    setOpenDelete(true);
  };

  const handleToggleExpand = (careerId) => {
    setExpandedCareer(expandedCareer === careerId ? null : careerId);
  };

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = careers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div>
      <h1>Careers</h1>
      <Button variant="contained" color="primary" onClick={() => setOpenAdd(true)}>Add Job Opening</Button>

      <TableContainer component={Paper} style={{ marginTop: '10px' }}>
        <Table style={{ tableLayout: 'fixed', marginTop: '10px' }} className="small-font">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Job Type</TableCell>
              <TableCell>Experience From</TableCell>
              <TableCell>Experience To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell style={{ width: '18%' }}>Created By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((career) => (
              <TableRow key={career._id}>
                <TableCell>{career.jobPosition}</TableCell>
                <TableCell>
                  <div>
                    {expandedCareer === career._id ? (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: career.jobDescription }} />
                        <Button onClick={() => handleToggleExpand(career._id)}>Show Less</Button>
                      </>
                    ) : (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: career.jobDescription.substring(0, 100) }} />
                        {career.jobDescription.length > 100 && (
                          <Button onClick={() => handleToggleExpand(career._id)}>Show More</Button>
                        )}
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell>{career.location}</TableCell>
                <TableCell>{career.jobType}</TableCell>
                <TableCell>{career.experienceFrom}</TableCell>
                <TableCell>{career.experienceTo}</TableCell>
                <TableCell>{career.status}</TableCell>
                <TableCell>{career.createdBy}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(career)}>Edit</Button>
                  {userRole === 'Admin' && <Button onClick={() => openDeleteConfirmation(career)}>Delete</Button>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Component */}
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Pagination
          count={Math.ceil(careers.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Snackbar for displaying API errors */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          style={{ backgroundColor: '#03a9f4' }}
          message={apiError}
        />
      </Snackbar>

      {/* Dialog for editing a job opening */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Edit Career</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="jobPosition"
            label="Title"
            type="text"
            fullWidth
            value={form.jobPosition}
            onChange={handleChange}
          />
          <Editor
            id='textarea'
            value={form.jobDescription || ""}
            onEditorChange={handleDescriptionChange}
            init={{
              height: 400,
              menubar: false,
              plugins: 'lists',
              toolbar: [
                'undo redo | bold italic | indent outdent bullist numlist'
            ]
            }}
          />
          <TextField
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            value={form.location}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="experienceFrom"
            label="Experience From"
            type="text"
            fullWidth
            value={form.experienceFrom}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="experienceTo"
            label="Experience To"
            type="text"
            fullWidth
            value={form.experienceTo}
            onChange={handleChange}
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              name="status"
              value={form.status}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="Ongoing" control={<Radio />} label="Ongoing" />
              <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
            </RadioGroup>
          </FormControl>
           <FormControl fullWidth margin="dense">
            <FormLabel>Job Type</FormLabel>
            <Select
              name="jobType"
              value={form.jobType}
              onChange={handleChange}
            >
              <MenuItem value="Full Time">Full Time</MenuItem>
              <MenuItem value="Part Time">Part Time</MenuItem>
              <MenuItem value="Contract">Contract</MenuItem>
              <MenuItem value="Freelancing">Freelancing</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding a new job opening */}
      <Dialog open={openAdd} onClose={() => setOpenAdd(false)}>
        <DialogTitle>Add Career</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="jobPosition"
            label="Title"
            type="text"
            fullWidth
            value={form.jobPosition}
            onChange={handleChange}
          />
          <Editor
            id='textarea'
            value={form.jobDescription || ""}
            onEditorChange={handleDescriptionChange}
            init={{
              height: 400,
              menubar: false,
              plugins: 'lists',
              toolbar: [
                'undo redo | bold italic | indent outdent bullist numlist'
            ]
            }}
          />
          <TextField
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            value={form.location}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="experienceFrom"
            label="Experience From"
            type="text"
            fullWidth
            value={form.experienceFrom}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="experienceTo"
            label="Experience To"
            type="text"
            fullWidth
            value={form.experienceTo}
            onChange={handleChange}
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              name="status"
              value={form.status}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="Ongoing" control={<Radio />} label="Ongoing" />
              <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <FormLabel>Job Type</FormLabel>
            <Select
              name="jobType"
              value={form.jobType}
              onChange={handleChange}
            >
              <MenuItem value="Full Time">Full Time</MenuItem>
              <MenuItem value="Part Time">Part Time</MenuItem>
              <MenuItem value="Contract">Contract</MenuItem>
              <MenuItem value="Freelancing">Freelancing</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd(false)} color="primary">Cancel</Button>
          <Button onClick={handleAdd} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for confirming deletion of a job opening */}
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Delete Career</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job opening?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Careers;
