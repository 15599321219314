// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-font {
    font-size: 12px;
    padding: 4px;
  }
  
  .small-font th,
  .small-font td {
    padding: 4px; /* Reduce padding */
  }
  `, "",{"version":3,"sources":["webpack://./src/css/font.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;EACd;;EAEA;;IAEE,YAAY,EAAE,mBAAmB;EACnC","sourcesContent":[".small-font {\r\n    font-size: 12px;\r\n    padding: 4px;\r\n  }\r\n  \r\n  .small-font th,\r\n  .small-font td {\r\n    padding: 4px; /* Reduce padding */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
