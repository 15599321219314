import React, {useState, useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
    MenuItem,Select,FormHelperText,
  Input, FormControl, InputLabel,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  DialogContentText,
  TablePagination,
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Testimonial = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(null);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    clientName: '',
    testimonial: '',
    rating: '',
    company: '',
    file: null,
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const userRole = useSelector(state => state.user.userRole);
  const username = useSelector(state => state.user.username);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axiosInstance.get('/testimonials/getTestimonials');
      setTestimonialData(response.data);
    } catch (error) {
      if(error.response.status === 403){
        localStorage.removeItem("token")
        navigate("/")
      }
      console.error('Error fetching testimonials:', error);
      setErrorMessage('Failed to fetch testimonials. Please try again later.');
    }
  };
  
  const handleDelete = async id => {
    setCurrentTestimonial(id);
    setDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      await axiosInstance.delete(`/testimonials/deleteTestimonial/${currentTestimonial}`);
      fetchTestimonials();
      setSuccessMessage('Testimonial deleted successfully.');
    } catch (error) {
      if(error.response.status === 403){
        localStorage.removeItem("token")
        navigate("/")
      }
      console.error('Error deleting testimonial:', error);
      setErrorMessage('Failed to delete testimonial. Please try again later.');
    }
    setDeleteConfirmation(false);
  };

  const handleEdit = item => {
    setCurrentTestimonial(item);
    setForm({
      clientName: item.clientName,
      testimonial: item.testimonial,
      rating: item.rating,
      company: item.company || '', // Ensure company field is set
      file: item.clientImageUrl || null,
    });
    setOpenEdit(true);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('clientName', form.clientName);
      formData.append('testimonial', form.testimonial);
      formData.append('rating', form.rating);
      formData.append('company', form.company); // Add company field to form data
      formData.append('createdBy', username);
      if (form.file) {
        formData.append('file', form.file);
      }
      console.log(form)
      await axiosInstance.put(`/testimonials/editTestimonial/${currentTestimonial._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setOpenEdit(false);
      fetchTestimonials();
      setForm({
        clientName: '',
        testimonial: '',
        rating: '',
        company: '',
        file: null,
      });
      setSuccessMessage('Testimonial updated successfully.');
    } catch (error) {
      
      console.error('Error updating testimonial:', error);
      if(error.response.status === 403){
        localStorage.removeItem("token")
        navigate("/")
      }
      setErrorMessage('Failed to update testimonial. Please try again later.');
    }
  };

  const handleChange = e => {
    const {name, value, type, files} = e.target;
    if (type === 'file') {
      setForm(prevForm => ({...prevForm, [name]: files[0]}));
    } else {
      setForm(prevForm => ({...prevForm, [name]: value}));
    }
  };

  const handleAdd = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      try {
        const formData = new FormData();
        formData.append('clientName', form.clientName);
        formData.append('testimonial', form.testimonial);
        formData.append('rating', form.rating);
        formData.append('company', form.company); // Add company field to form data
        formData.append('createdBy', username);
        if (form.file) {
          formData.append('file', form.file);
        }

        await axiosInstance.post('/testimonials/addTestimonial', formData);

        setOpenAdd(false);
        fetchTestimonials();
        setForm({
          clientName: '',
          testimonial: '',
          rating: '',
          company: '',
          file: null,
        });
        setSuccessMessage('Testimonial added successfully.');
      } catch (error) {
        if(error.response.status === 403){
          localStorage.removeItem("token")
          navigate("/")
        }
        console.error('Error adding testimonial:', error);
        setErrorMessage('Failed to add testimonial. Please try again later.');
      }
    } else {
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.testimonial) newErrors.testimonial = 'Testimonial is required';
    if (!form.rating) newErrors.rating = 'Rating is required';
    if (!form.company) newErrors.company = 'Company name is required';
    if (!form.file) newErrors.file = 'Client image is required';
    return newErrors;
  };
  

  const handleCloseSnackbar = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTextAreaChange = e => {
    const {value} = e.target;
    if (value.length <= 250) {
      setForm(prevForm => ({...prevForm, testimonial: value}));
    }
  };

  return (
    <div>
      <h1>Testimonials</h1>
      <Button variant="contained" color="primary" onClick={() => setOpenAdd(true)}>
        Add Testimonial
      </Button>

      <TableContainer component={Paper} style={{marginTop: '10px'}}>
        <Table style={{tableLayout: 'fixed', marginTop: '10px'}} className="small-font">
          <TableHead>
            <TableRow>
              <TableCell style={{width: '20%', border: '1px solid #ddd'}}>Client Name</TableCell>
              <TableCell style={{width: '30%', border: '1px solid #ddd'}}>Testimonial</TableCell>
              <TableCell style={{width: '10%', border: '1px solid #ddd'}}>Rating</TableCell>
              <TableCell style={{width: '20%', border: '1px solid #ddd'}}>Client Image</TableCell>
              <TableCell style={{width: '15%', border: '1px solid #ddd'}}>Company</TableCell>
              <TableCell style={{width: '24%', border: '1px solid #ddd'}}>Created By</TableCell>
              <TableCell style={{width: '15%', border: '1px solid #ddd'}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonialData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
              <TableRow key={item._id}>
                <TableCell style={{border: '1px solid #ddd'}}>{item.clientName}</TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>{item.testimonial}</TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>{item.rating}</TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>
                  {item.clientImageUrl && (
                    <img src={item.clientImageUrl} alt="client" style={{width: '100px'}} />
                  )}
                </TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>{item.company}</TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>{item.createdBy}</TableCell>
                <TableCell style={{border: '1px solid #ddd'}}>
                  <Button onClick={() => handleEdit(item)}>Edit</Button>
                  {userRole === "Admin" && (
                    <Button onClick={() => handleDelete(item._id)}>Delete</Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={testimonialData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Snackbar for error messages */}
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar for success messages */}
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for editing a testimonial */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)} maxWidth="lg" fullWidth>
        <DialogTitle>Edit Testimonial</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="clientName"
            name="clientName"
            label="Client Name"
            type="text"
            fullWidth
            value={form.clientName}
            onChange={handleChange}
            error={!!errors.clientName}
            helperText={errors.clientName}
          />
          <TextField
            margin="dense"
            id="testimonial"
            name="testimonial"
            label="Testimonial"
            multiline
            fullWidth
            rows={4}
            value={form.testimonial}
            onChange={handleTextAreaChange}
            error={!!errors.testimonial}
            helperText={`${form.testimonial.length}/250`}
          />
        <FormControl fullWidth margin="dense">
      <InputLabel id="rating-label">Rating</InputLabel>
      <Select
        labelId="rating-label"
        id="rating"
        name="rating"
        value={form.rating}
        onChange={handleChange}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
      </Select>
    </FormControl>
          <TextField
            margin="dense"
            id="company"
            name="company"
            label="Company"
            type="text"
            fullWidth
            value={form.company}
            onChange={handleChange}
            error={!!errors.company}
            helperText={errors.company}
          />
         
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for delete confirmation */}
      <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
        <DialogTitle>Delete Testimonial</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding a new testimonial */}
      <Dialog open={openAdd} onClose={() => setOpenAdd(false)} maxWidth="lg" fullWidth>
  <DialogTitle>Add New Testimonial</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      id="clientName"
      name="clientName"
      label="Client Name"
      type="text"
      fullWidth
      value={form.clientName}
      onChange={handleChange}
    />
    <TextField
      margin="dense"
      id="testimonial"
      name="testimonial"
      label="Testimonial"
      multiline
      rows={4}
      fullWidth
      value={form.testimonial}
      onChange={handleTextAreaChange}
      error={!!errors.testimonial}
      helperText={errors.testimonial || `${form.testimonial.length}/250`}
    />
    <FormControl fullWidth margin="dense">
      <InputLabel id="rating-label" InputLabelProps={{shrink: true}}>Rating</InputLabel>
      <Select
        labelId="rating-label"
        id="rating"
        name="rating"
        value={form.rating}
        onChange={handleChange}
        error={!!errors.rating}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
      </Select>
      {errors.rating && <FormHelperText error>{errors.rating}</FormHelperText>}
    </FormControl>
    <TextField
      margin="dense"
      id="company"
      name="company"
      label="Company"
      type="text"
      fullWidth
      value={form.company}
      onChange={handleChange}
      error={!!errors.company}
      helperText={errors.company}
    />
    <FormControl margin="dense" fullWidth>
      <label>Upload Client Image:</label>
      <Input
        id="file"
        name="file"
        type="file"
        onChange={handleChange}
        error={!!errors.file}
      />
      {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenAdd(false)}>Cancel</Button>
    <Button onClick={handleAdd} color="primary">
      Add
    </Button>
  </DialogActions>
</Dialog>
    </div>
  );
};

export default Testimonial;
