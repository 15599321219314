import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import {
  Dashboard,
  People,
  BarChart,
  ExitToApp,
  PhotoLibrary,
  RateReview,
  Work,
  AdminPanelSettings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "./logo.svg";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const SideMenu = ({ onSelect }) => {
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.username);
  const userRole = useSelector((state) => state.user.userRole);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuth");
    navigate("/");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", height: "auto", padding: "10px" }}
          />
        </Typography>
      </Toolbar>
      <Divider />
      <div>
        <List>
          <ListItem>
            <ListItemIcon style={{ marginRight: "5" }}>
              <Avatar sx={{ marginRight: 2 }}>
                {username.split("@")[0][0].toUpperCase()}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={username.split("@")[0].toUpperCase()} />
          </ListItem>
          <Divider />
          {userRole === "Admin" && (
            <>
              <ListItem
                button
                onClick={() => {
                  onSelect("jobApplication");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <Work />
                </ListItemIcon>
                <ListItemText primary="Job Applications" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("careers");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="Careers" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("blogs");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary="Blogs" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("news");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <BarChart />
                </ListItemIcon>
                <ListItemText primary="News" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("gallery");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <PhotoLibrary />
                </ListItemIcon>
                <ListItemText primary="Gallery" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("testimonials");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <RateReview />
                </ListItemIcon>
                <ListItemText primary="Testimonials" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("manageUsers");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <AdminPanelSettings />
                </ListItemIcon>
                <ListItemText primary="Manage Users" />
              </ListItem>
            </>
          )}
          {userRole === "Editor" && (
            <>
              <ListItem
                button
                onClick={() => {
                  onSelect("blogs");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary="Blogs" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("news");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <BarChart />
                </ListItemIcon>
                <ListItemText primary="News" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("gallery");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <PhotoLibrary />
                </ListItemIcon>
                <ListItemText primary="Gallery" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("testimonials");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <RateReview />
                </ListItemIcon>
                <ListItemText primary="Testimonials" />
              </ListItem>
            </>
          )}
          {userRole === "Hr" && (
            <>
              <ListItem
                button
                onClick={() => {
                  onSelect("jobApplication");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <Work />
                </ListItemIcon>
                <ListItemText primary="Job Applications" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  onSelect("careers");
                  navigate("/dashboard");
                }}
              >
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="Careers" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideMenu;
