import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, TextField, Typography} from '@mui/material';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setUserRole, setUserName} from '../redux/actions/userActions';
import logo from './logo.svg';
import axiosInstance from '../axiosInstance';

const Login = ({onLogin}) => {
  const [username, setUsername] = useState ('');
  const [password, setPassword] = useState ('');
  const navigate = useNavigate ();
  const dispatch = useDispatch ();

  const handleSubmit = async e => {
    e.preventDefault ();
    const form = {username, password};
    try {
      console.log(process.env.REACT_APP_API_URL)
      const response = await axiosInstance.post (`/user/login`, form);
      console.log (response);

      const {token, username, role} = response.data;
      localStorage.setItem ('token', token);
      dispatch (setUserName (username));
      dispatch (setUserRole (role));

      onLogin ();
      navigate ('/dashboard'); // Navigate to the dashboard on successful login
    } catch (error) {
      console.error ('Login error:', error);
      alert ('Invalid credentials');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 8,
      }}
    >
      <img
        className=""
        src={logo}
        alt="logo"
        style={{width: '150px', height: 'auto', padding: '20px'}}
      />
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{width: '100%', maxWidth: 360}}
      >
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          variant="outlined"
          value={username}
          onChange={e => setUsername (e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          margin="normal"
          variant="outlined"
          type="password"
          value={password}
          onChange={e => setPassword (e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{mt: 2}}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
