const initialState = {
    userRole: '',
    username: '' // Initial state for user role
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER_ROLE':
        return {
          ...state,
          userRole: action.payload,
        };
        case 'SET_USER_NAME':
            return {
              ...state,
              username: action.payload,
            };
      default:
        return state;
    }
  };
  
  export default userReducer;
  