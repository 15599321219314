import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  TablePagination, DialogContentText, Snackbar, Alert
} from '@mui/material';
import dayjs from 'dayjs';
import axiosInstance from '../axiosInstance';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../css/font.css';

const News = () => {
  const [newsData, setNewsData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const userRole = useSelector((state) => state.user.userRole);
  const [currentNews, setCurrentNews] = useState(null);
  const username = useSelector((state) => state.user.username);
  const [form, setForm] = useState({
    title: '',
    subTitle: '',
    place: '',
    date: '',
    description: '',
    source: '',
    file: null,
  });
  const [errors, setErrors] = useState({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currentNewsToDelete, setCurrentNewsToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axiosInstance.get('/news/getAllNews');
      setNewsData(response.data);
    } catch (error) {
      handleError(error, 'Error fetching news');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/news/deleteNews/${id}`);
      fetchNews();
      handleSnackbar('News deleted successfully', 'success');
    } catch (error) {
      handleError(error, 'Error deleting news');
    }
  };

  const handleDeleteConfirmation = (news) => {
    setCurrentNewsToDelete(news);
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (currentNewsToDelete) {
      try {
        await handleDelete(currentNewsToDelete._id);
        setOpenDeleteConfirmation(false);
      } catch (error) {
        handleError(error, 'Error confirming delete');
      }
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleEdit = (news) => {
    setCurrentNews(news);
    setForm({
      title: news.title,
      subTitle: news.subTitle,
      place: news.place,
      date: dayjs(news.date).format('YYYY-MM-DD'),
      description: news.description,
      source: news.source,
      file: null,
    });
    setOpenEdit(true);
  };
 

  const handleSave = async () => {
    const data = {
      ...form,
      createdBy: username
    };
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (data[key] || key !== 'date') { // Only append non-empty values or if key is not 'date'
        formData.append(key, data[key]);
      }
    });


    try {
      await axiosInstance.put(`/news/editNews/${currentNews._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setOpenEdit(false);
      fetchNews();
      handleSnackbar('News updated successfully', 'success');
    } catch (error) {
      handleError(error, 'Error saving news');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setForm((prevForm) => ({ ...prevForm, [name]: files[0] }));
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const handleAdd = async () => {
    
    const newErrors = validateForm();
  
    // If there are any validation errors, do not proceed with submission.
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Exit the function if there are errors
    }
  
    try {
      setAttemptedSubmit(true);
      const data = {
        ...form,
        createdBy: username,
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] || key !== 'date') {
          // Only append non-empty values or if key is not 'date'
          formData.append(key, data[key]);
        }
      });
  
      await axiosInstance.post('/news/addNews', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setOpenAdd(false);
      fetchNews();
      handleSnackbar('News added successfully', 'success');
      
      // Reset form and submission state
      setForm({
        title: '',
        subTitle: '',
        place: '',
        date: '',
        description: '',
        source: '',
        file: null,
      });
      setAttemptedSubmit(false); 
      setErrors({}); // Clear errors on successful submission
    } catch (error) {
      handleError(error, 'Error adding news');
    }
  };
  
  const validateForm = () => {
    const newErrors = {};
    if (attemptedSubmit) { // Show errors only if form has been submitted
      if (!form.title) newErrors.title = 'Title is required';
      if (!form.place) newErrors.place = 'Place is required';
      if (!form.description) newErrors.description = 'Description is required';
      if (!form.source) newErrors.source = 'Source is required';
      if (!form.file) newErrors.file = 'File is required';
    }
    console.log(newErrors)
    return newErrors;
  };

  const formatDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const truncateDescription = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  };

  const toggleExpand = (newsId) => {
    const updatedNewsData = newsData.map(news => {
      if (news._id === newsId) {
        return {
          ...news,
          expanded: !news.expanded
        };
      }
      return news;
    });
    setNewsData(updatedNewsData);
  };

  const renderDescription = (news, description, maxLength, expanded) => {
    if (expanded) {
      return (
        <span>
          <div dangerouslySetInnerHTML={{__html: description}} />
          <Button size="small" onClick={() => toggleExpand(news._id)}>Show Less</Button>
        </span>
      );
    } else {
      return (
        <span>
           <div
            dangerouslySetInnerHTML={{
              __html: truncateDescription (description, maxLength),
            }}
          />
          {description.length > maxLength && <Button size="small" onClick={() => toggleExpand(news._id)}>Show More</Button>}
        </span>
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDescriptionChange = (content) => {
    setForm((prevForm) => ({ ...prevForm, description: content }));
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleError = (error, defaultMessage) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      navigate('/');
    } else {
      handleSnackbar(error.response?.data?.message || defaultMessage, 'error');
    }
  };

  return (
    <div>
      <h1>News</h1>
      <Button variant="contained" color="primary" onClick={() => setOpenAdd(true)}>Add news</Button>
      
      <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'fixed', marginTop: '10px' }} className="small-font">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%', border: '1px solid #ddd' }}>Title</TableCell>
              <TableCell style={{ width: '15%', border: '1px solid #ddd' }}>Subtitle</TableCell>
              <TableCell style={{ width: '20%', border: '1px solid #ddd' }}>Place</TableCell>
              <TableCell style={{ width: '18%', border: '1px solid #ddd' }}>Date</TableCell>
              <TableCell style={{ width: '20%', border: '1px solid #ddd' }}>Description</TableCell>
              <TableCell style={{ width: '20%', border: '1px solid #ddd' }}>Image</TableCell>
              <TableCell style={{ width: '15%', border: '1px solid #ddd' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((news) => (
              <TableRow key={news._id}>
                <TableCell>{news.title}</TableCell>
                <TableCell>{news.subTitle}</TableCell>
                <TableCell>{news.place}</TableCell>
                <TableCell>{formatDate(news.date)}</TableCell>
                <TableCell
                    style={{textAlign: 'left', border: '1px solid #ddd'}}
                  >
                    {renderDescription (
                      news,
                      news.description,
                      200,
                      news.expanded
                    )}
                  </TableCell>
                <TableCell>
                  {news.fileUrl ? <img src={news.fileUrl} alt="news" style={{ width: '100px' }} /> : 'No Image'}
                </TableCell>
                <TableCell>
                <Button
                      onClick={() => handleEdit (news)}
                      disabled={userRole === 'editor'}
                    >
                      Edit
                    </Button>
                    {userRole === "Admin" &&
                    <Button
                      onClick={() => handleDeleteConfirmation (news)}
                      disabled={userRole === 'editor'}
                    >
                      Delete
                    </Button>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[9]}
        component="div"
        count={newsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openAdd} onClose={() => setOpenAdd(false)}>
        <DialogTitle>Add News</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            fullWidth
            value={form.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={attemptedSubmit && errors.title}
          />
          <TextField
            margin="dense"
            name="subTitle"
            label="Subtitle"
            fullWidth
            value={form.subTitle}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="place"
            label="Place"
            fullWidth
            value={form.place}
            onChange={handleChange}
            error={!!errors.place}
            helperText={attemptedSubmit && errors.place}
          />
          <TextField
            margin="dense"
            name="date"
            label="Date"
            type="date"
            fullWidth
            value={form.date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
          <Editor
            name="description"
            value={form.description}
            onEditorChange={handleDescriptionChange}
            init={{
              height: 400,
              menubar: false,
              plugins: 'lists',
              toolbar: [
                'undo redo | bold italic | indent outdent bullist numlist'
            ]
            }}
          />
          {attemptedSubmit && errors.description && (
            <DialogContentText color="error">{errors.description}</DialogContentText>
          )}
          <TextField
            margin="dense"
            name="source"
            label="Source"
            fullWidth
            value={form.source}
            onChange={handleChange}
            error={!!errors.source}
            helperText={attemptedSubmit && errors.source}
          />
          <input
            type="file"
            name="file"
            accept="image/*"
            onChange={handleChange}
            style={{ marginTop: '10px' }}
          />
          {attemptedSubmit && errors.file && (
            <DialogContentText color="error">{errors.file}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Edit News</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            name="title"
            value={form.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            margin="dense"
            label="Subtitle"
            type="text"
            fullWidth
            name="subTitle"
            value={form.subtitle}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Place"
            type="text"
            fullWidth
            name="place"
            value={form.place}
            onChange={handleChange}
            error={!!errors.place}
            helperText={errors.place}
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            name="date"
            value={form.date}
            onChange={handleChange}
            InputLabelProps={{shrink: true}}
          />
          <DialogContentText>Description:</DialogContentText>
          <Editor
            value={form.description}
            onEditorChange={handleDescriptionChange}
            init={{
              height: 400,
              menubar: false,
              plugins: 'lists',
              toolbar: [
                'undo redo | bold italic | indent outdent bullist numlist'
            ]
            }}
          />
          <TextField
            margin="dense"
            label="Source"
            type="text"
            fullWidth
            name="source"
            value={form.source}
            onChange={handleChange}
          />
          <input
            type="file"
            accept="image/*"
            name="file"
            onChange={handleChange}
            style={{ marginTop: '16px' }}
          />
        </DialogContent>
    
        
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this news item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default News;
