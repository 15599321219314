import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SideMenu from './components/SideMenu';
import Blogs from './components/Blogs';
import News from './components/News';
import Login from './components/Login';
import Careers from './components/Careers';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import JobApplication from './components/JobApplications';
import ManageUsers from './components/ManageUsers';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(localStorage.getItem('selectedMenu') || 'careers');

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuth', true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAuth');
    localStorage.removeItem('selectedMenu'); // Remove selected menu from local storage on logout
    setIsAuthenticated(false);
  };

  const handleMenuSelect = (menu) => {
    setSelectedMenu(menu);
    localStorage.setItem('selectedMenu', menu); // Store the selected menu in local storage
  };

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuth');
    if (isAuth) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/dashboard/*"
          element={
            isAuthenticated ? (
              <Box sx={{ display: 'flex' }}>
                <SideMenu onSelect={handleMenuSelect} onLogout={handleLogout} />
                <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
                  <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                          Welcome to your Dashboard!
                          {selectedMenu === 'careers' && <Careers />}
                          {selectedMenu === 'blogs' && <Blogs />}
                          {selectedMenu === 'news' && <News />}
                          {selectedMenu === 'gallery' && <Gallery />}
                          {selectedMenu === 'testimonials' && <Testimonials />}
                          {selectedMenu === 'jobApplication' && <JobApplication />}
                          {selectedMenu === 'manageUsers' && <ManageUsers />}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </Box>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
