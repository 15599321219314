import React, {useState, useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ManageUsers = () => {
  const [users, setUsers] = useState ([]);
  const [openAdd, setOpenAdd] = useState (false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState (false);
  const [form, setForm] = useState ({
    username: '',
    password: '',
    role: '',
  });
  const [errors, setErrors] = useState ({});
  const [errorMessage, setErrorMessage] = useState ('');
  const [showSnackbar, setShowSnackbar] = useState (false);
  const [userIdToDelete, setUserIdToDelete] = useState (null);
  const username = useSelector (state => state.user.username);
  const navigate = useNavigate();

  useEffect (() => {
    fetchUsers ();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get ('user/getUsers');
      setUsers (response.data);
    } catch (error) {
      console.error ('Error fetching users:', error);
      setErrorMessage ('Error fetching users');
    }
  };

  const handleAdd = async () => {
    try {
      const data = {
        ...form,
        createdBy: username,
      };
      await axiosInstance.post ('/user/addUser', data);
      fetchUsers ();
      setOpenAdd (false);
      setForm ({username: '', password: '', role: ''});
    } catch (error) {
      console.error ('Error adding user:', error);
      if(error.response.status === 403){
        localStorage.removeItem("token")
        navigate("/")
      }
      setErrorMessage ('Error adding user');
      setShowSnackbar (true);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete (`/user/deleteUser/${userIdToDelete}`);
      fetchUsers ();
      setOpenDeleteConfirm (false);
      setUserIdToDelete (null);
    } catch (error) {
      console.error ('Error deleting user:', error);
      setErrorMessage ('Error deleting user');
      setShowSnackbar (true);
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setForm (prevForm => ({...prevForm, [name]: value}));
  };

  const handleRoleChange = e => {
    setForm (prevForm => ({...prevForm, role: e.target.value}));
  };

  const handleOpenAdd = () => {
    setForm ({username: '', password: '', role: ''});
    setOpenAdd (true);
  };

  const handleCloseAdd = () => {
    setForm ({username: '', password: '', role: ''});
    setOpenAdd (false);
  };

  const handleOpenDeleteConfirm = userId => {
    setUserIdToDelete (userId);
    setOpenDeleteConfirm (true);
  };

  const handleCloseDeleteConfirm = () => {
    setUserIdToDelete (null);
    setOpenDeleteConfirm (false);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar (false);
  };

  return (
    <div>
      <h1>Manage Users</h1>
      <Button variant="contained" color="primary" onClick={handleOpenAdd}>
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>CreatedBy</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map (user => (
              <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.createdBy}</TableCell>
                <TableCell>
                  {user.username === "admin@cybercraftz.com" ? "" :
                  <Button onClick={() => handleOpenDeleteConfirm (user._id)}>
                    Delete
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openAdd} onClose={handleCloseAdd}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={form.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={form.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <FormControl
            component="fieldset"
            margin="dense"
            error={!!errors.role}
          >
            <FormLabel component="legend">Role</FormLabel>
            <RadioGroup
              aria-label="role"
              name="role"
              value={form.role}
              onChange={handleRoleChange}
            >
              <FormControlLabel
                value="Admin"
                control={<Radio />}
                label="Admin"
              />
              <FormControlLabel
                value="Editor"
                control={<Radio />}
                label="Editor"
              />
              <FormControlLabel
                value="Hr"
                control={<Radio />}
                label="Hr"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdd}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{width: '100%'}}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ManageUsers;
