import React, {useState, useEffect} from 'react';
import axiosInstance from '../axiosInstance';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TablePagination,
  Snackbar,
  Alert,
} from '@mui/material';

const JobApplication = () => {
  const [appData, setAppData] = useState ([]);
  const [open, setOpen] = useState (false);
  const [selectedApplicant, setSelectedApplicant] = useState (null);
  const [comment, setComment] = useState ('');
  const [page, setPage] = useState (0);
  const [rowsPerPage, setRowsPerPage] = useState (5);
  const [snackbarOpen, setSnackbarOpen] = useState (false);
  const [snackbarMessage, setSnackbarMessage] = useState ('');
  const [snackbarSeverity, setSnackbarSeverity] = useState ('success');

  const fetchApplications = async () => {
    try {
      const response = await axiosInstance.get (
        '/jobApplication/getApplications'
      );
      const sortedData = response.data.sort (
        (a, b) => new Date (b.date) - new Date (a.date)
      );
      setAppData (sortedData);
    } catch (error) {
      console.error (error);
      setSnackbarMessage ('Error fetching applications');
      setSnackbarSeverity ('error');
      setSnackbarOpen (true);
    }
  };

  useEffect (() => {
    fetchApplications ();
  }, []);

  const handleOpen = applicant => {
    setSelectedApplicant (applicant);
    setOpen (true);
  };

  const handleClose = () => {
    setOpen (false);
    setSelectedApplicant (null);
  };

  const handleAddComment = async () => {
    try {
      await axiosInstance.put (
        `/jobApplication/updateComment/${selectedApplicant._id}`,
        {comment}
      );
      setComment ('');
      fetchApplications ();
      setSnackbarMessage ('Comment added successfully');
      setSnackbarSeverity ('success');
      setSnackbarOpen (true);
    } catch (error) {
      console.error (error);
      setSnackbarMessage ('Error adding comment');
      setSnackbarSeverity ('error');
      setSnackbarOpen (true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage (newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage (parseInt (event.target.value, 10));
    setPage (0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen (false);
  };

  return (
    <div>
      <h1>Job Applications</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Job Position</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Current Employer</TableCell>
              <TableCell>Current CTC</TableCell>
              <TableCell>Expected CTC</TableCell>
              <TableCell>Notice Period</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appData
              .slice (page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map (applicant => (
                <TableRow key={applicant._id}>
                  <TableCell
                  >{`${applicant.firstName} ${applicant.lastName}`}</TableCell>
                  <TableCell>{applicant.jobPosition}</TableCell>
                  <TableCell>{applicant.experience} years</TableCell>
                  <TableCell>{applicant.currentEmployer}</TableCell>
                  <TableCell>{applicant.currentCTC}</TableCell>
                  <TableCell>{applicant.expectedCTC}</TableCell>
                  <TableCell>{applicant.noticePeriod}</TableCell>
                  <TableCell>
                    {applicant.comment
                      ? applicant.comment
                      : 'No comments added yet'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpen (applicant)}
                    >
                      More Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={appData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md" // Change this to "lg" if you want it even wider
        fullWidth
      >
        <DialogTitle>Job Application Details</DialogTitle>
        <DialogContent>
          {selectedApplicant &&
            <div>
              <p>
                <strong>Job Position:</strong> {selectedApplicant.jobPosition}
              </p>
              <p>
                <strong>Name:</strong>
                {' '}
                {`${selectedApplicant.firstName} ${selectedApplicant.lastName}`}
              </p>
              <p><strong>Email:</strong> {selectedApplicant.email}</p>
              <p><strong>Phone:</strong> {selectedApplicant.phone}</p>
              <p>
                <strong>Year of Graduation:</strong>
                {' '}
                {selectedApplicant.yearOfGraduation}
              </p>
              <p><strong>Gender:</strong> {selectedApplicant.gender}</p>
              <p>
                <strong>Experience:</strong>
                {' '}
                {selectedApplicant.experience}
                {' '}
                years
              </p>
              <p>
                <strong>Current Employer:</strong>
                {' '}
                {selectedApplicant.currentEmployer}
              </p>
              <p>
                <strong>Current CTC:</strong> {selectedApplicant.currentCTC}
              </p>
              <p>
                <strong>Expected CTC:</strong> {selectedApplicant.expectedCTC}
              </p>
              <p>
                <strong>Notice Period:</strong> {selectedApplicant.noticePeriod}
              </p>
              <p><strong>Skills:</strong> {selectedApplicant.skills}</p>
              <p><strong>Source:</strong> {selectedApplicant.source}</p>
              <p>
                <strong>Current Location:</strong>
                {' '}
                {selectedApplicant.currentLocation}
              </p>
              <p>
                <strong>Preferred Location:</strong>
                {' '}
                {selectedApplicant.preferredLocation}
              </p>
              <p>
                <strong>Resume:</strong>
                {' '}
                <a
                  href={selectedApplicant.resume}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: 'blue', textDecoration: 'underline'}}
                >
                  Resume link
                </a>
              </p>
              <p>
                <strong>Comments:</strong>
                {' '}
                {selectedApplicant.comment
                  ? selectedApplicant.comment
                  : 'No comments added yet'}
              </p>
              <TextField
                margin="dense"
                label="Add a comment"
                fullWidth
                multiline
                rows={4}
                value={comment}
                onChange={e => setComment (e.target.value)}
              />
            </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleAddComment} color="primary">
            Add Comment
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{width: '100%'}}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default JobApplication;
