import React, {useState, useEffect} from 'react';
import axiosInstance from '../axiosInstance';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TablePagination,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import dayjs from 'dayjs';
import {Editor} from '@tinymce/tinymce-react';
import '../css/font.css';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const [blogData, setBlogData] = useState ([]);
  const [openAdd, setOpenAdd] = useState (false);
  const [openEdit, setOpenEdit] = useState (false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState (false);
  const [currentBlog, setCurrentBlog] = useState (null);
  const userRole = useSelector (state => state.user.userRole);
  const username = useSelector (state => state.user.username);
  const [form, setForm] = useState ({
    title: '',
    subTitle: '',
    place: '',
    date: '',
    description: '',
    source: '',
    file: null,
  });
  const [errors, setErrors] = useState ({});
  const [page, setPage] = useState (0);
  const [rowsPerPage, setRowsPerPage] = useState (9);
  const navigate = useNavigate()

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState (false);
  const [snackbarMessage, setSnackbarMessage] = useState ('');
  const [apiError, setApiError] = useState ('');

  useEffect (() => {
    fetchBlogs ();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get ('/blogs/getBlogs');
      setBlogData (response.data);
    } catch (error) {
      handleApiError (error);
    }
  };

  const handleDelete = id => {
    setCurrentBlog (id);
    setOpenDeleteConfirmation (true); // Open delete confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      await axiosInstance.delete (`/blogs/deleteBlog/${currentBlog}`);
      fetchBlogs ();
      setSnackbarMessage ('Blog deleted successfully');
      setSnackbarOpen (true);
      setOpenDeleteConfirmation (false); // Close confirmation dialog after deletion
    } catch (error) {
      handleApiError (error);
    }
  };

  const handleEdit = blog => {
    setCurrentBlog (blog);
    setForm ({
      title: blog.title,
      subTitle: blog.subTitle,
      place: blog.place,
      date: dayjs (blog.date).format ('YYYY-MM-DD'),
      description: blog.description,
      source: blog.source,
      file: null,
    });
    setOpenEdit (true);
  };

  const handleSave = async () => {
    try {
      const data = {
        ...form,
        createdBy: username,
      };
      const formData = new FormData ();
      Object.keys (data).forEach (key => {
        if (data[key] || key !== 'date') { // Only append non-empty values or if key is not 'date'
          formData.append(key, data[key]);
        }
      });

      await axiosInstance.put (`/blogs/editBlog/${currentBlog._id}`, formData);
      setOpenEdit (false);
      fetchBlogs ();
      setSnackbarMessage ('Blog edited successfully');
      setSnackbarOpen (true);
    } catch (error) {
      handleApiError (error);
    }
  };

  const handleChange = e => {
    const {name, value, type, files} = e.target;
    if (type === 'file') {
      setForm (prevForm => ({...prevForm, [name]: files[0]}));
    } else {
      setForm (prevForm => ({...prevForm, [name]: value}));
    }
  };

  const handleDescriptionChange = content => {
    setForm (prevForm => ({...prevForm, description: content}));
  };

  const handleAdd = async () => {
    const newErrors = validateForm ();
    console.log(username)
    if (Object.keys (newErrors).length === 0) {
      try {
        const data = {
          ...form,
          createdBy: username,
        };
        const formData = new FormData ();
        Object.keys (data).forEach (key => {
          if (data[key] || key !== 'date') { // Only append non-empty values or if key is not 'date'
            formData.append(key, data[key]);
          }
        });

        await axiosInstance.post ('/blogs/createBlog', formData);
        setOpenAdd (false);
        fetchBlogs ();
        setForm ({
          title: '',
          subTitle: '',
          place: '',
          date: '',
          description: '',
          source: '',
          file: null,
          createdBy: '',
        });
        setSnackbarMessage ('Blog added successfully');
        setSnackbarOpen (true);
      } catch (error) {
        handleApiError (error);
      }
    } else {
      setErrors (newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.title) newErrors.title = 'Title is required';
    if (!form.place) newErrors.place = 'Place is required';
    if (!form.description) newErrors.description = 'Description is required';
    if (!form.file) newErrors.file = 'File is required';
    return newErrors;
  };

  const formatDate = date => {
    return dayjs (date).format ('YYYY-MM-DD');
  };

  const truncateDescription = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring (0, maxLength)}...`;
    } else {
      return text;
    }
  };

  const toggleExpand = blogId => {
    const updatedBlogData = blogData.map (blog => {
      if (blog._id === blogId) {
        return {
          ...blog,
          expanded: !blog.expanded,
        };
      }
      return blog;
    });
    setBlogData (updatedBlogData);
  };

  const renderDescription = (blog, description, maxLength, expanded) => {
    if (expanded) {
      return (
        <span>
          <div dangerouslySetInnerHTML={{__html: description}} />
          <Button size="small" onClick={() => toggleExpand (blog._id)}>
            Show Less
          </Button>
        </span>
      );
    } else {
      return (
        <span>
          <div
            dangerouslySetInnerHTML={{
              __html: truncateDescription (description, maxLength),
            }}
          />
          {description.length > maxLength &&
            <Button size="small" onClick={() => toggleExpand (blog._id)}>
              Show More
            </Button>}
        </span>
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage (newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage (parseInt (event.target.value, 10));
    setPage (0);
  };

  const handleApiError = error => {
    if(error.response.status === 403){
      localStorage.removeItem("token")
      navigate("/")
    }
    if (error.response && error.response.data && error.response.data.message) {
      setApiError (error.response.data.message);
    } else {
      setApiError ('An error occurred.');
    }
    setSnackbarOpen (true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen (false);
    setApiError ('');
    setSnackbarMessage ('');
  };

  return (
    <div>
      <h1>Blogs</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenAdd (true)}
      >
        Add Blog
      </Button>

      <TableContainer component={Paper}>
        <Table
          style={{tableLayout: 'fixed', marginTop: '10px'}}
          className="small-font"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{width: '20%', border: '1px solid #ddd'}}>
                Title
              </TableCell>
              <TableCell style={{width: '15%', border: '1px solid #ddd'}}>
                Subtitle
              </TableCell>
              <TableCell style={{width: '20%', border: '1px solid #ddd'}}>
                Place
              </TableCell>
              <TableCell style={{width: '18%', border: '1px solid #ddd'}}>
                Date
              </TableCell>
              <TableCell style={{width: '30%', border: '1px solid #ddd'}}>
                Created By
              </TableCell>
              <TableCell style={{width: '30%', border: '1px solid #ddd'}}>
                Content
              </TableCell>
              <TableCell style={{width: '30%', border: '1px solid #ddd'}}>
                Image
              </TableCell>
              <TableCell
                style={{
                  width: '15%',
                  border: '1px solid #ddd',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Source
              </TableCell>
              <TableCell style={{width: '15%', border: '1px solid #ddd'}}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogData
              .slice (page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map (blog => (
                <TableRow key={blog._id}>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {blog.title}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {blog.subTitle}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {blog.place}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {formatDate (blog.date)}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {blog.createdBy}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'left', border: '1px solid #ddd'}}
                  >
                    {renderDescription (
                      blog,
                      blog.description,
                      200,
                      blog.expanded
                    )}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    {blog.fileUrl &&
                      <img
                        src={blog.fileUrl}
                        alt="Blog"
                        style={{width: '100px', height: 'auto'}}
                      />}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      border: '1px solid #ddd',
                      wordWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {blog.source}
                  </TableCell>
                  <TableCell
                    style={{textAlign: 'center', border: '1px solid #ddd'}}
                  >
                    <Button
                      onClick={() => handleEdit (blog)}
                      disabled={userRole === 'editor'}
                    >
                      Edit
                    </Button>
                    {userRole === "Admin" &&
                    <Button
                      onClick={() => handleDelete (blog._id)}
                      disabled={userRole === 'editor'}
                    >
                      Delete
                    </Button>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={blogData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Add Blog Dialog */}
      <Dialog open={openAdd} onClose={() => setOpenAdd (false)}>
        <DialogTitle>Add Blog</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="Title"
            value={form.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            name="subTitle"
            label="Subtitle"
            value={form.subTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="place"
            label="Place"
            value={form.place}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.place}
            helperText={errors.place}
          />
          <TextField
            name="date"
            label="Date"
            type="date"
            value={form.date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{shrink: true}}
          />
          <div style={{margin: '20px 0'}}>
            
             <Editor
             id="textarea"
              value={form.description}
              onEditorChange={handleDescriptionChange}
              init={{
                height: 400,
                menubar: false,
                plugins: 'lists',
                toolbar: [
                  'undo redo | bold italic | indent outdent bullist numlist'
              ]
              }}
            /> 
          </div>
          <TextField
            name="source"
            label="Source"
            value={form.source}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <input
            accept="image/*"
            type="file"
            name="file"
            onChange={handleChange}
            style={{marginTop: '20px'}}
          />
          {errors.file && (
  <div id="file-error" style={{color: 'red', marginTop: '10px'}}>
    {errors.file}
  </div>
)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd (false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Blog Dialog */}
      <Dialog open={openEdit} onClose={() => setOpenEdit (false)}>
        <DialogTitle>Edit Blog</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="Title"
            value={form.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            name="subTitle"
            label="Subtitle"
            value={form.subTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="place"
            label="Place"
            value={form.place}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.place}
            helperText={errors.place}
          />
          <TextField
            name="date"
            label="Date"
            type="date"
            value={form.date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{shrink: true}}
          />
          <div style={{margin: '20px 0'}}>
            <Editor
              value={form.description}
              onEditorChange={handleDescriptionChange}
              init={{
                height: 400,
                menubar: false,
                plugins: 'lists',
                toolbar: [
                  'undo redo | bold italic | indent outdent bullist numlist'
              ]
              }}
            />
          </div>
          <TextField
            name="source"
            label="Source"
            value={form.source}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            accept="image/*"
            name="file"
            onChange={handleChange}
            style={{ marginTop: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit (false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation (false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this blog?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation (false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          message={apiError || snackbarMessage}
          style={{backgroundColor: apiError ? 'red' : 'green'}}
        />
      </Snackbar>
    </div>
  );
};

export default Blogs;
